import s from "./ModuleValuesItem.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import {ReactComponent as ArrowTools} from "assets/icons/arrow_tools.svg";
import {ReactComponent as TrashIcon} from "assets/icons/trash.svg";
import questionMarkImage from "assets/images/questionMark.webp";
import {ReactComponent as EditIcon} from "assets/icons/edit_icon.svg";
import {ReactComponent as PlusIcon} from "assets/icons/plus.svg";

import {useSelector} from "react-redux";
import {useDeleteFromArrayModuleMutation, useDeleteModuleMutation} from "../../../../../../redux/apis/modulesApi";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useStatusContext} from "components/StatusProvider";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import {Preloader} from "components/Preloader/Preloader";
import {DropDown} from "../../../../../../components/DropDown/DropDown";
import {DropdownBlock} from "../../../../../../components/DropdownBlock/DropdownBlock";

type ModuleValuesItemProps = {
    object: any;
    sectionId: number;
    refetchGetSection: any;
    setEditModule: React.Dispatch<any>;
    setIsAddModule: React.Dispatch<boolean>;
    setModuleId: React.Dispatch<number>;
    setIdSection: React.Dispatch<number>;
    setElementOfArray: React.Dispatch<any>;
    setScrollId: React.Dispatch<number>;
    hidden: any;
    setHidden: React.Dispatch<any>;
    currentModule: number;
    setCurrentModule: React.Dispatch<number>;
}

type RenderModuleValuesProps = {
    values: any[];
    isParentArray?: boolean;
    type?: string
};

type ModuleValuesItemsProps = {
    values: any;
};

export const ModuleValuesItem = ({
                                     object,
                                     sectionId,
                                     refetchGetSection,
                                     setEditModule,
                                     setIsAddModule,
                                     setModuleId,
                                     setIdSection,
                                     setElementOfArray,
                                     setScrollId,
                                     hidden,
                                     setHidden,
                                     currentModule,
                                     setCurrentModule
}: ModuleValuesItemProps) => {
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);
    const [langState, setLangState] = useState(langs?.[0]?.key);
    const [deleteModule, {isLoading: isLoadingDeleteModule}] = useDeleteModuleMutation();
    const [deleteElement, {isLoading: isLoadingDeleteElement}] = useDeleteFromArrayModuleMutation();

    const [idDeletedModule, setIdDeletedModule] = useState<number>(0);
    const [showModuleDelete, setShowModuleDelete] = useState<boolean>(false);
    const [showModuleDeleteElement, setShowModuleDeleteElement] = useState<boolean>(false);

    const { openStatus } = useStatusContext();
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    let arrayIsManual: any[] = []

    const elementRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    if (langs.isFetching || isLoadingDeleteModule || isLoadingDeleteElement) return <Preloader />

    const handleDeleteModule = async (sectionId: number, moduleId: number) => {
        try {
            const response = await deleteModule({ id: moduleId, section_id: sectionId }).unwrap();
            if (response.message === "ok") {
                openStatus('success', "status.operationSuccessful");
                setShowModuleDelete(false);
                refetchGetSection()
            } else {
                openStatus('error', "status.error");
            }
        } catch (error) {
            console.error('Error deleting module:', error);
        }
    };

    const openModalDelete = (moduleId: number) => {
        setIdDeletedModule(moduleId);
        setShowModuleDelete(true);
    }

    const classType = (type: string) => cn(s.type, {
        [s.type_array]: type === "array",
        [s.type_object]: type === "object",
    });

    const handleArrowClick = (id: number) => {
        setHidden((prevState: any) =>
            prevState.includes(id)
                ? prevState.filter((item: any) => item !== id)
                : [...prevState, id]
        );
    };

    const goToAddModule = () => {
        setIsAddModule(true);
        setModuleId(object?.type_id);
        setIdSection(sectionId);
    }

    const setting = () => {
        return (
            <div className={s.setting}>
                <ArrowTools className={s.setting_arrow}
                            // onClick={() => handleArrowClick(object?.type_id)}
                />
                <PlusIcon onClick={goToAddModule} />
            </div>
        )
    }

    const handleDeleteElement = async (id: number) => {
        try {
            const response = await deleteElement({ id: id }).unwrap();
            if (response.message === "ok") {
                openStatus('success', "status.operationSuccessful");
                setShowModuleDelete(false);
                refetchGetSection()
            } else {
                openStatus('error', "status.error");
            }
        } catch (error) {
            console.error('Error deleting module:', error);
        }
    };

    const toggleHeight = (id: number) => {
        handleArrowClick(id);

        const el = elementRefs.current[id];

        if (!el) return;

        if (!hidden?.includes(id)) {
            el.style.height = el.scrollHeight + 'px';
            el.style.overflowY = 'visible';
        } else {
            el.style.height = '54px';
            el.style.overflowY = 'hidden';
        }
    };

    const RenderModuleValues = ({ values, isParentArray, type }: RenderModuleValuesProps) => {
        const renderValueItem = (value: any, index: number, containerClass: string, isParentArray: boolean) => (
            <div
                className={cn(
                    containerClass,
                    {[s.main_type]: value?.type !== "array" && value?.type !== "object"},
                    `anchor-${value?.id}`
                )}
                key={index}
            >
                <div
                    ref={(el) => (elementRefs.current[value.id] = el)}
                    className={`${s.module_values_item} ${!hidden?.includes(value.id) ? s.hide_1 : ""} anchor-${value?.id}`}
                >
                    <div className={s.item_name} onClick={() => {
                        toggleHeight(value?.id);
                        // handleArrowClick(value?.id)
                    }}>
                        <div className={s.main_info}>
                            <div className={s.data}>
                                <p>{t("pagesModal.name")}:</p>
                                <span>{value?.name}</span>
                            </div>
                            <div className={s.data}>
                                <p>{t("pages.marker")}:</p>
                                <span>{value?.marker}</span>
                            </div>

                            {value?.link && <div className={s.data}>
                                <p>{t("pages.link")}:</p>
                                <span>{value?.link}</span>
                            </div>}

                        </div>
                        <span className={classType(value?.type)}>{value?.type}</span>
                        <div className={s.main_info}>
                            <div className={s.data_small}>
                                <p>id:</p>
                                <span>{value?.module_id}</span>
                            </div>
                        </div>

                        {isParentArray && <TrashIcon onClick={() => {
                            setIdDeletedModule(value?.module_id);
                            setShowModuleDeleteElement(true);
                            handleArrowClick(value?.id);
                        }}/>}

                        <div className={s.setting}>
                            <ArrowTools className={s.setting_arrow}/>
                            {(value?.type !== "array" && value?.type !== "object") &&
                                <EditIcon className={s.setting_edit} onClick={() => {
                                    setEditModule({show: true, element: value})
                                    setScrollId(value?.id)
                                    handleArrowClick(value?.id);
                                }}/>
                            }
                            {value?.type === "array" &&
                                <PlusIcon onClick={() => {
                                    setElementOfArray({show: true, element: value?.values?.[0]})
                                    setModuleId(value?.id)
                                }}/>
                            }
                        </div>
                    </div>

                    {value?.values &&
                        <RenderModuleValues
                            values={value?.values}
                            isParentArray={value?.type === "array" && value?.values?.length > 1}
                            type={value?.type}
                        />
                    }
                </div>
            </div>
        );

        const renderItems = (values: any, isParentArray: boolean) => {
            if (typeof values === 'string') {
                return <p
                    className={s.other_type}>{type === "date" ? new Date(Number(values)).toLocaleString() : values}</p>;

            } else if (Array.isArray(values)) {
                const itemsForModule = values?.filter((value) => !value?.values);
                const manualMediaItems = values?.filter((value: any) =>
                    value?.values &&
                    (value?.type === "image" || value?.type === "video") &&
                    value.isManual
                );

                arrayIsManual.push(...manualMediaItems);

                return (
                    <>
                        {itemsForModule.length > 0 && <ModuleValuesItems values={itemsForModule}/>}

                        {values.map((value, index) => {
                            return (value?.values || value?.values === "")
                                ? renderValueItem(value, index, s.container_first, isParentArray)
                                : null;
                        })}
                    </>
                );
            } else if (typeof values === 'object' && values !== null) {
                const itemsForModule = Object.values(values).filter((value: any) => !value?.values);
                const manualMediaItems = Object.values(values).filter((value: any) =>
                    value?.values &&
                    (value?.type === "image" || value?.type === "video") &&
                    value.isManual
                );
                arrayIsManual.push(...manualMediaItems);

                return (
                    <>
                        {itemsForModule.length > 0 && <ModuleValuesItems values={itemsForModule}/>}

                        {Object.values(values).map((value: any, index) =>
                            value?.values ? renderValueItem(value, index, s.container_second, false) : <p>fd</p>
                        )}
                    </>
                );
            }

            return null;
        };

        return <>{renderItems(values, isParentArray || false)}</>;
    };

    const ModuleValuesItems = ({values}: ModuleValuesItemsProps) => {
        const isImagePath = (value: string) =>
            /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(value);

        const isVideoPath = (value: string) =>
            /\.(mp4|mov|avi|mkv|webm)$/i.test(value);

        const isValues = values?.some((item: any) =>
            item && item.lang_key === String(langState)
        )

        if (!isValues) return null;

        return (
            <div className={s.module_values}>
                <div className={`${s.container_third}`}>
                    <>
                        {values?.some((v: any) => isImagePath(v?.value) || isVideoPath(v?.value)) ? (
                            <div className={s.image_wrapper}>
                                {values?.map((v: any, index: number) => {
                                    if (isImagePath(v?.value) && (arrayIsManual?.some(el => el.type === "image" && el.isManual) || v.default_image)) {
                                        return (
                                            <div key={index} className={s.media_wrapper}>
                                                {arrayIsManual?.some(el => el.type === "image" && el.isManual) && (
                                                    <>
                                                        {v?.lang_key?.toUpperCase()}
                                                        {v?.default_image && " | Default"}
                                                    </>
                                                )}
                                                <img
                                                    src={`${API_ADDRESS}${v?.value}`}
                                                    alt={`image-${index}`}
                                                    className={s.image}
                                                />
                                            </div>
                                        );
                                    } else if (isVideoPath(v?.value) && (arrayIsManual?.some(el => el.type === "video" && el.isManual) || v.default_image) ) {
                                        return (
                                            <div key={index} className={s.media_wrapper}>
                                                {arrayIsManual?.some(el => el.type === "video" && el.isManual) && (
                                                    <>
                                                        {v?.lang_key?.toUpperCase()}
                                                        {v?.default_image && " | Default"}
                                                    </>
                                                )}
                                                <video
                                                    src={`${API_ADDRESS}${v?.value}`}
                                                    controls
                                                    className={s.video}
                                                >
                                                    Ваш браузер не підтримує відео тег.
                                                </video>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        ) : (
                            <div className={s.content}>
                                <div className={s.text_block}>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                values?.filter((item: any) =>
                                                    item && item.lang_key === String(langState)
                                                )?.[0]?.value || "",
                                        }}
                                    ></p>
                                </div>

                                <div className={s.lang_block}>
                                    {langs?.map((el: any) => (
                                        <div
                                            key={el.key}
                                            className={`${s.lang_item} ${
                                                el.key === String(langState) && s.lang_item_active
                                            }`}
                                            onClick={() => setLangState(el.key)}
                                        >
                                            <div className={s.lang}>{el.key}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </div>
        );
    };

    return (
        <div className={s.modules}>
            <div className={`${s.container}`}>
                <div className={`${s.module} ${!hidden?.includes(object?.type_id) && s.hide} anchor-${object?.type_id} `}>
                    <div className={s.module_header} onClick={() => handleArrowClick(object?.type_id)}>
                        <div className={s.module_header_flex}>
                        {object?.values?.map((module: any, index: number) => (
                            <SecondaryButton
                                text={module?.name}
                                type={"button"}
                                className={`${s.button_marker} ${index === currentModule && s.button_marker_active}`}
                                onClick={() => {
                                    setCurrentModule(index)
                                    hidden.includes(object?.type_id) && handleArrowClick(object?.type_id)
                                }}
                                icon={<TrashIcon onClick={() => openModalDelete(module?.id)}/>}
                            />
                        ))}
                        </div>
                        {setting()}
                    </div>
                    <DropdownBlock open={!hidden?.includes(object?.type_id)}>
                        <div className={s.module_values}>
                            {Array.isArray(object?.values?.[currentModule]?.values) ? (
                                <div className={s.module_values_item}>
                                    <RenderModuleValues values={object?.values?.[currentModule]?.values}/>
                                </div>
                            ) : object?.values?.[currentModule]?.values ? (
                                <RenderModuleValues values={Object.values(object?.values?.[currentModule]?.values)}/>
                            ) : null}
                        </div>
                    </DropdownBlock>
                </div>
            </div>

            <ModalGeneral
                description={`${t("general.areYouSure")} module? ${t("general.itsContents")}`}
                image={questionMarkImage}
                buttonRight={() => handleDeleteModule(sectionId, idDeletedModule)}
                setShowModal={setShowModuleDelete}
                showModal={showModuleDelete}
                textRightButton={t("general.delete")}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
            />

            <ModalGeneral
                description={`${t("general.areYouSure")} it? ${t("general.itsContents")}`}
                image={questionMarkImage}
                buttonRight={() => handleDeleteElement(idDeletedModule)}
                setShowModal={setShowModuleDeleteElement}
                showModal={showModuleDeleteElement}
                textRightButton={t("general.delete")}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
            />
        </div>
    )
}