import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import s from './StaisticsGraph.module.scss';
import { configureDashboardDate } from 'utils/configureDashboardDate';
import { useEffect } from 'react';
import { useSetAttendanceMutation } from '../../../../../../redux/apis/statisticsApi';
import { Skeleton } from 'components/Skeleton/Skeleton';

export const StaisticsGraph = ({data, query, activeLines, isLoading}: any) => {
    
    // ### State
    const [ setAttendance ] = useSetAttendanceMutation()
    // ### Effects
    useEffect(()=>{
        handleAttendance()
    },[]);
    const handleAttendance = async () => {
        await setAttendance({refer: document.referrer})
    }

    // ### Functions
    useEffect(()=>{
        
    },[]);

    // ### Views
    const lines = activeLines.map((el: any, index: number) => {
        return (
          <Line
            key={index}
            type="linear"
            name={el.title}
            dataKey={el.name}
            stroke={el.color}
            strokeWidth={3}
            strokeOpacity={1}
            dot={false}
            style={{ filter: `drop-shadow(0px 0px 4px ${el.color})` }}
          />
        );
    });

    return(
        <div className={s.wrapper}>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                data={configureDashboardDate(data, {startDate: query.startDate, endDate: query.endDate})}
                margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
                >
                <XAxis dataKey="date" />
                <CartesianGrid />
                {lines}
                <Tooltip />
                </LineChart>
            </ResponsiveContainer>
            <Skeleton isLoading={isLoading} />
        </div>
    )
}