import {configureStore, Middleware} from "@reduxjs/toolkit";
import { blocksApi } from "./apis/blocksApi";
import { langApi } from "./apis/langApi";
import { mediaApi } from "./apis/mediaApi";
import { pagesApi } from "./apis/pagesApi";
import { postsApi } from "./apis/postsApi";
import { sectionApi } from "./apis/sectionApi";
import { termsApi } from "./apis/termsApi";
import { customerApi } from "./apis/administratorApi";
import mainSlice from "./slices/mainSlice";
import { modulesApi } from "./apis/modulesApi";
import { shopApi } from "./apis/shopApi";
import {blogApi} from "./apis/blogApi";
import {documentsApi} from "./apis/documentsApi";
import {fileExplorerApi} from "./apis/fileExplorerApi";
import {usersApi} from "./apis/usersApi";
import {referralApi} from "./apis/referralApi";
import {statisticsApi} from "./apis/statisticsApi";
import { mailingApi } from './apis/mailingApi';

const interceptorMiddleware: Middleware = store => next => (action: any) => {
    const result: any = next(action);

    if (result?.payload?.status === 401) {
        console.error('Unauthorized! Redirecting to login...');
        window.location.replace('/auth');
    }

    return result;
};


export const store = configureStore({
    reducer : {
        main: mainSlice,
        [customerApi.reducerPath]: customerApi.reducer,
        [termsApi.reducerPath]: termsApi.reducer,
        [langApi.reducerPath]: langApi.reducer,
        [sectionApi.reducerPath]: sectionApi.reducer,
        [blocksApi.reducerPath]: blocksApi.reducer,
        [mediaApi.reducerPath]: mediaApi.reducer,
        [pagesApi.reducerPath]: pagesApi.reducer,
        [postsApi.reducerPath]: postsApi.reducer,
        [modulesApi.reducerPath]: modulesApi.reducer,
        [shopApi.reducerPath]: shopApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
        [documentsApi.reducerPath]: documentsApi.reducer,
        [fileExplorerApi.reducerPath]: fileExplorerApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [referralApi.reducerPath]: referralApi.reducer,
        [statisticsApi.reducerPath]: statisticsApi.reducer,
        [mailingApi.reducerPath]: mailingApi.reducer
    },
    middleware : 
        (getDefaultMiddleware) => getDefaultMiddleware().concat(
            interceptorMiddleware,
            customerApi.middleware, 
            termsApi.middleware,
            langApi.middleware,
            sectionApi.middleware,
            blocksApi.middleware,
            mediaApi.middleware,
            pagesApi.middleware,
            postsApi.middleware,
            modulesApi.middleware,
            shopApi.middleware,
            blogApi.middleware,
            documentsApi.middleware,
            fileExplorerApi.middleware,
            usersApi.middleware,
            referralApi.middleware,
            statisticsApi.middleware,
            mailingApi.middleware
       ),
});
