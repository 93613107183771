import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import s from './EditModule.module.scss';
import {Back} from "components/Back/Back";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {DropDown} from "components/DropDown/DropDown";
import {useSelector} from "react-redux";
import {Input} from "components/Input/Input";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useUpdateBlockMutation} from "../../../../../../redux/apis/blocksApi";
import {useStatusContext} from "components/StatusProvider";
import {goToModule} from "utils/goToModule";
import {Preloader} from "components/Preloader/Preloader";
import {extractIds, findById} from "utils/moduleAdd";
import {sendMedia} from "utils/sendMedia";
import {useUpdateMediaMutation} from "../../../../../../redux/apis/mediaApi";
import {Progress} from "components/Progress/Progress";
import DatePicker from "react-datepicker";

type EditModuleProps = {
    element: any;
    setEditModule: React.Dispatch<any>;
    refetchGetSection: any;
}

export const EditModule = ({ element, setEditModule, refetchGetSection }: EditModuleProps) => {
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);
    const [updateBlock, {isLoading: isLoadingUpdateBlock}] = useUpdateBlockMutation();
    const { openStatus } = useStatusContext();
    const [defaultImage, setDefaultImage] = useState<any>([]);
    const [isManual, setIsManual] = useState<number[]>(element?.isManual ? [element?.id] : []);

    useEffect(() => {
        if (Array.isArray(element?.values)) {
            element?.values?.forEach((value: any) => {
                if (value?.default_image) {
                    setDefaultImage([...defaultImage , { id: element.id, key: value.lang_key}]);
                }
            })
        }
    }, []);

    if (langs?.isFetching || isLoadingUpdateBlock) return <Preloader />

    const initialValues: any = {
        link: element?.link
    };

    if (Array.isArray(element?.values)) {
        element.values.forEach((value: any) => {
            initialValues[`${element?.type}-${value?.lang_key}`] = value?.value;
        });
    } else if (typeof element?.values === 'string') {
        initialValues[element?.type] = element.values;
    }

    const validation_data: any = {}

    const handleSubmit = async (values: any) => {
        let changedValues: any = [];

        if (element.type === "number" || element.type === "slug" || element.type === "date") {
            for (let val in values) {
                if (element.type === "date") {
                    changedValues = new Date(values[val]).getTime()
                } else {
                    changedValues = values[val]
                }
            }
        } else {
            langs?.map((lang: any) => {
                for (let val in values) {
                    if (val.includes(lang?.key)) {
                        const initialValue = initialValues[val];
                        const newValue = values[val];

                        if (element?.type === "image" || element?.type === "video") {
                            changedValues.push({
                                file: initialValue === newValue ? initialValue : newValue,
                                lang_key: lang?.key,
                                default_image: defaultImage.some((el: any) => el.key === lang.key && el.id === element.id)
                            });
                        } else {
                            changedValues.push({
                                value: newValue,
                                lang_key: lang?.key,
                                default_image: defaultImage.some((el: any) => el.key === lang.key && el.id === element.id)
                            });
                        }
                    }
                }
            });
        }

        const result: any = {
            block_id: element?.id,
            type: element?.type,
            link: values?.link,
            isManual: isManual.includes(element.id)
        };

        if (element?.type === "image" || element?.type === "video") {
            result["files"] = changedValues
        } else if (element.type === "number" || element.type === "slug" || element.type === "date") {
            result["value"] = changedValues
        } else {
            result["terms"] = changedValues
        }

        const response = await updateBlock(result).unwrap();

        if (response?.message === "block_successfully_updated") {
            openStatus('success', "status.operationSuccessful");
            refetchGetSection();
            setEditModule({ show: false });
            goToModule(element?.id);
        } else {
            openStatus('error', "status.error");
        }
    };

    const validationSchema = yup.object().shape(validation_data);

    return (
        <div className={s.container}>
            <Back close={setEditModule} text={t("blocksModal.backToBlocks")} />

            <div className={s.form}>
                <h2>{t("moduleModal.modifyModule")}</h2>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched, setFieldValue, values}) => (
                        <Form className={s.field_container}>
                            {(element.type !== "number" &&  element.type !== "slug" && element.type !== "date") && <DropDown
                                langs={langs}
                                errors={errors}
                                touched={touched}
                                kind={element?.type}
                                label={element?.type}
                                values={values}
                                setFieldValue={setFieldValue}
                                setDefaultImage={setDefaultImage}
                                defaultImage={defaultImage}
                                isManual={isManual}
                                setIsManual={setIsManual}
                                id={element?.id}
                            />}

                            {element?.link && <Input
                                value={values.link}
                                label={t("pages.link")}
                                errors={errors.link}
                                touched={touched.link}
                                name={"link"}
                                onClick={() => setFieldValue('link', '')}
                            />}

                            {element.type === "number" &&
                                <Input
                                    type="number"
                                    value={values.number}
                                    label={t("pages.number")}
                                    errors={errors.number}
                                    touched={touched.number}
                                    name={"number"}
                                    onClick={() => setFieldValue("number", '')}
                                />
                            }
                            {element.type === "slug" &&
                                <Input
                                    value={values.slug}
                                    label={"Slug"}
                                    errors={errors.slug}
                                    touched={touched.slug}
                                    name={"slug"}
                                    onClick={() => setFieldValue("slug", '')}
                                />
                            }
                            {element.type === "date" &&
                                <DatePicker
                                    selected={new Date(Number(values.date))}
                                    onChange={(date: Date | null) => setFieldValue("date", date)}
                                    placeholderText={"Date"}
                                    dateFormat="dd.MM.yyyy, HH:mm:ss"
                                    showTimeSelect
                                />
                            }

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={t("general.confirm")}
                                    type={"submit"}
                                    icon={<Check />}
                                />
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    white
                                    onClick={() => setEditModule({show: false})}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}