import React, {useEffect, useState} from 'react';
import {useAddCustomerMutation, useUpdateCustomerMutation} from "../../redux/apis/administratorApi";
import {useCrypto} from "utils/crypto";
import s from "./AddUser.module.scss";
import { Form, Formik} from "formik";
import * as yup from 'yup';
import {Back} from "../Back/Back";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import {Input} from "../Input/Input";
import {useStatusContext} from "../StatusProvider";
import {useTranslation} from "react-i18next";
import {Preloader} from "../Preloader/Preloader";

type AddUser = {
    close: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    user?: any;
}

export const AddUser = ({ close, user }: AddUser) => {
    const { openStatus } = useStatusContext();
    const { t } = useTranslation();
    const [permissions, setPermissions] = useState({
            "pages": user?.permissions?.pages ?? false,
            "shop": user?.permissions?.shop ?? false,
            "blog": user?.permissions?.blog ?? false,
            "storage": user?.permissions?.storage ?? false
    });
    const [addUser] = useAddCustomerMutation();
    const [updUser] = useUpdateCustomerMutation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(()=>{
        console.log("user", user);
        if (user) setPermissions(user?.permissions)
    },[]);


    if (isLoading) {
        return <Preloader />
    }

    const initialValues = {
        email: user ? user.email : '',
        name: user ? user?.info?.name:'',
        password: '',
        confirmPassword: '',
        role: user ? user?.info?.role : '',
    }

    const validationSchema = yup.object().shape({
        email: yup.string().required(t("validation.required")).email(t("validation.incorrectEmail")),
        password: yup.string().test(
            "password-validation",
            t("validation.required"),
            function (value) {
                if (user) {
                    return true; 
                }
                return !!value; 
            }
        )
        .min(14, t("validation.min"))
        .matches(/[A-Z]/, t("validation.oneCapitalLetter"))
        .matches(/[0-9]/, t("validation.oneDigit")),
        confirmPassword: yup.string().test(
            "confirm-password-validation",
            t("validation.required"),
            function (value) {
                const { user, password } = this.parent;
                if (!user && !password) return true; 
                return !!value; 
            }
        )
        .oneOf([yup.ref("password")], t("validation.passwordsDMatch")),
        name: yup.string().required(t("validation.required")),
        role: yup.string().required(t("validation.required")),
    });

    async function Registration(values: any){
        
        const email = values.email;
        const password = useCrypto(values.password);
        const name = values.name;
        const role = values.role;
        setIsLoading(true);

        const result = await addUser({
            email: email,
            password: password,
            name: name,
            role: role,
            permissions: permissions
        });

        if (result.data) {
            if(result.data.message === 1) {
                openStatus('error', `${t("customers.email")} ${result.data.data} ${t("customers.alreadyRegistered")}`);
                setIsLoading(false);
            }
            if (result.data.message === "registration_successful") {
                close(false);
                openStatus('success', "status.operationSuccessful");
                setIsLoading(false);
            }
        } else {
            openStatus('error', "status.thereWasAnError");
            setIsLoading(false);
        }
    }

    async function UpdateUser(values: any){
        const id = user.id;
        const email = values.email;
        const name = values.name;
        const role = values.role;
        const password =  useCrypto(values.password)
        setIsLoading(true);

        const result = await updUser({
            id,
            email,
            role,
            name,
            password: values.password?.length ? password : undefined,
            permissions
        });

        if (result.data) {
             if (result.data.message === "user_successfully_updated") {
                 close(false);
                 openStatus('success', "status.operationSuccessful");
                 setIsLoading(false);
             }
        } else {
            openStatus('error', "status.thereWasAnError");
            setIsLoading(false);
        }
    }

    function selectPermission(value: any, group: string) {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [group]: value,
        }));
    }
    
    return (
        <div className={s.add_user}>
            <Back close={close} text={t("addUser.backToUsers")} />

            <div className={s.form}>
                <h1>{user ? t("addUser.editUser"): t("customers.addUser")}</h1>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        if (!user) Registration(values);
                        else UpdateUser(values);
                    }}
                >
                    {({errors, touched, setFieldValue, values}) => (
                        <Form className={s.auth_form}>
                            <div className={s.auth_form_border}>
                                <div className={s.auth_form_field}>
                                    <div className={s.form_row}>
                                        <Input
                                            value={values.email}
                                            label={t("customers.email")}
                                            errors={errors.email}
                                            touched={touched.email}
                                            name={"email"}
                                            placeholder={t("addUser.enterAddress")}
                                            onClick={() => setFieldValue('email', '')}
                                        />

                                        <Input
                                            value={values.name}
                                            label={t("pagesModal.name")}
                                            errors={errors.name}
                                            touched={touched.name}
                                            name={"name"}
                                            placeholder={t("addUser.enterYourUserName")}
                                            onClick={() => setFieldValue('name', '')}
                                        />
                                    </div>

                                    <div className={s.form_row}>
                                        <Input
                                            value={values.password}
                                            label={user ? t("addUser.new_password") : t("addUser.password")}
                                            errors={errors.password}
                                            touched={touched.password}
                                            name={"password"}
                                            placeholder={t("addUser.enterYourPassword")}
                                            password
                                        />

                                        <Input
                                            value={values.confirmPassword}
                                            label={t("addUser.confirmPassword")}
                                            errors={errors.confirmPassword}
                                            touched={touched.confirmPassword}
                                            name={"confirmPassword"}
                                            placeholder={t("addUser.confirmPassword")}
                                            password
                                        />
                                    </div>

                                    <Input
                                        value={values.role}
                                        label={t("customers.role")}
                                        errors={errors.role}
                                        touched={touched.role}
                                        name={"role"}
                                        placeholder={t("addUser.enterRole")}
                                        onClick={() => setFieldValue('role', '')}
                                    />
                                </div>

                                <div className={s.access}>
                                    <h2>{t("addUser.accessRights")}</h2>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.pagesManagement")}</p>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-pages"
                                                value="pages"
                                                defaultChecked={permissions?.pages}
                                                onChange={(ev) =>
                                                    selectPermission(true, "pages")
                                                }
                                            />
                                            {t("addUser.yes")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-pages"
                                                value="no-pages"
                                                defaultChecked={!permissions?.pages}
                                                onChange={(ev) =>
                                                    selectPermission( false, "pages")
                                                }
                                            />
                                            {t("addUser.no")}
                                        </label>
                                    </div>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.shopManagement")}</p>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-shop"
                                                value="shop"
                                                defaultChecked={permissions?.shop}
                                                onChange={(ev) =>
                                                    selectPermission(true, "shop")
                                                }
                                            />
                                            {t("addUser.yes")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-shop"
                                                value="no-shop"
                                                defaultChecked={!permissions?.shop}
                                                onChange={(ev) =>
                                                    selectPermission( false, "shop")
                                                }
                                            />
                                            {t("addUser.no")}
                                        </label>
                                    </div>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.blogManagement")}</p>
                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-blog"
                                                value="blog"
                                                defaultChecked={permissions?.blog}
                                                onChange={(ev) => {
                                                    selectPermission(true, "blog");
                                                }}
                                            />
                                            {t("addUser.yes")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-blog"
                                                value="blog"
                                                defaultChecked={!permissions?.blog}
                                                onChange={(ev) => {
                                                    selectPermission(false, "blog");
                                                }}
                                            />
                                            {t("addUser.no")}
                                        </label>
                                    </div>
                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.storageManagement")}</p>
                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-storage"
                                                value="storage"
                                                defaultChecked={permissions?.storage}
                                                onChange={(ev) => {
                                                    selectPermission(true, "storage");
                                                }}
                                            />
                                            {t("addUser.yes")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-storage"
                                                value="storage"
                                                defaultChecked={!permissions?.storage}
                                                onChange={(ev) => {
                                                    selectPermission(false, "storage");
                                                }}
                                            />
                                            {t("addUser.no")}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={s.wrap_buttons}>
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    onClick={() => close(false)}
                                />
                                <PrimaryButton text={t("general.confirm")} type={"submit"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}